import React, { useState, useEffect } from "react";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import { useSpring, animated } from "@react-spring/web";
import FacebookNude from "./../../assets/svg/logo/social/facebook-nude.svg";
import InstagramNude from "./../../assets/svg/logo/social/instagram-nude.svg";
import FacebookPoudre from "./../../assets/svg/logo/social/facebook-poudre.svg";
import InstagramPoudre from "./../../assets/svg/logo/social/instagram-poudre.svg";
import LalcoveSoloNameNude from "./../../assets/svg/logo/logo_nude/logo_nom_solo_nude.svg";
import LalcoveSoloNamePoudre from "./../../assets/svg/logo/logo_poudre/logo_nom_solo_poudre.svg";
import Home from "../../pages/home";
import About from "../../pages/about";
import Benefits from "../../pages/benefits";
import Prices from "../../pages/prices";
import LegalNotice from "../../pages/legalNotice";
import RendezVous from "../rendez-vous/rendez-vous";
import useModal from "../useModal";
import IsRouteExist from "../IsRouteExist";
import { useBreakpoint } from "../../utils/useBreakpointLogger";

export default function Navbar() {
  const breakpoint = useBreakpoint();

  // Animation settings
  const anim = useSpring({
    opacity: 0.95,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(0px, -47rem)" },
    config: { duration: 400 },
  });

  // State management
  const [isVisible, setIsVisible] = useState(true);
  const [buttonColorClass, setButtonColorClass] = useState(
    "col-start-12 cursor-pointer font-semibold color-nude flex items-center text-lg rounded-full m-auto justify-center -ml-20"
  );

  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;

      setIsVisible(winScroll <= 50);

      // Change button color logic
      let newButtonColorClass =
        "col-start-12 cursor-pointer font-semibold color-nude flex items-center text-lg rounded-full m-auto justify-center -ml-20";

      if (
        (location.pathname === "/a-propos" &&
          winScroll >= 2300 &&
          winScroll <= 3200) ||
        (location.pathname === "/prestations" && winScroll >= 170)
      ) {
        newButtonColorClass =
          "blue-button col-start-12 cursor-pointer font-semibold color-nude flex items-center text-lg rounded-full m-auto justify-center -ml-20";
      }

      setButtonColorClass(newButtonColorClass);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [location]);

  // Modal management
  const { isOpen, toggle } = useModal();

  // Navigation color state
  const [navColor, setNavColor] = useState("#efe0d0");
  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/a-propos") {
      setNavColor("#efe0d0");
    } else if (
      location.pathname === "/prestations" ||
      location.pathname === "/nos-tarifs" ||
      location.pathname === "/mentions-legal" ||
      !IsRouteExist(location.pathname)
    ) {
      setNavColor("#d9a08c");
    }
  }, [location]);

  const aProposStyle = {
    color: location.pathname === "/a-propos" ? "#031933" : navColor,
    transform:
      breakpoint === "md" || breakpoint === "lg" ? "scale(.75)" : "none",
    marginLeft: breakpoint === "md" || breakpoint === "lg" ? "-150px" : "none",
  };

  const prestaStyle = {
    color: location.pathname === "/prestations" ? "#031933" : navColor,
    transform:
      breakpoint === "md" || breakpoint === "lg" ? "scale(.75)" : "none",
    marginLeft: breakpoint === "md" || breakpoint === "lg" ? "-200px" : "none",
  };

  const tarifsStyle = {
    color: location.pathname === "/nos-tarifs" ? "#031933" : navColor,
    transform:
      breakpoint === "md" || breakpoint === "lg" ? "scale(.75)" : "none",
    marginLeft:
      breakpoint === "md" || breakpoint === "lg" ? "-270px" : "-150px",
  };

  const logosStyle = {
    marginLeft:
      breakpoint === "md" || breakpoint === "lg" ? "-11.25rem" : "-1.25rem",
  };

  const rdvBtnStyle = {
    width: 200,
    height: 38,
    padding: "2px 15px",
    letterSpacing: ".15em",
    fontSize: !isVisible ? 18 : "",
    marginRight: breakpoint === "lg" ? 101.3 : "none",
    marginLeft:
      isVisible && breakpoint === "md"
        ? -110
        : isVisible
        ? -170
        : breakpoint === "lg"
        ? "-5rem"
        : breakpoint === "md"
        ? "-6.6rem"
        : -43.3,
    marginTop: !isVisible ? 0.5 : breakpoint === "md" ? -35 : "",
    backgroundColor: location.pathname === "/a-propos" ? "#2e5a6b" : "#d9a08c",
    transform:
      breakpoint === "md" || breakpoint === "lg" ? "scale(.75)" : "none",
  };

  const takeRdv = () => {
    toggle();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const callBtnStyle = {
    backgroundColor: "#2e5a6b",
    borderRadius: "20px",
    fontVariant: "all-pnpm setite-caps",
    width: breakpoint === "xl" ? 330 : 220,
    height: 40,
    lineHeight: breakpoint === "xl" ? 2 : 1,
    marginTop: "-20px",
    fontSize: breakpoint === "xl" ? 18 : 14,
    cursor: "inherit",
    paddingTop: breakpoint === "xl" ? "none" : 5,
  };
  const planityBtnStyle = {
    backgroundColor: "#2e5a6b",
    borderRadius: "20px",
    fontVariant: "all-petite-caps",
    width: breakpoint === "xl" ? 330 : 220,
    height: "40px",
    lineHeight: 2,
    marginTop: "-20px",
    fontSize: 18,
  };

  return (
    <>
      <header>
        <animated.nav className="fixed mt-10 z-40" style={anim}>
          <div
            style={{
              transform: breakpoint === "md" ? "translate(-115px)" : "",
            }}
            className="grid grid-cols-12 helvetica-neue"
          >
            {isVisible && (
              <Link className="col-start-3 col-end-4 -ml-16" to="/">
                <img
                  src={
                    location.pathname === "/a-propos" ||
                    location.pathname === "/"
                      ? LalcoveSoloNameNude
                      : LalcoveSoloNamePoudre
                  }
                  alt="lalcoveSolo"
                />
              </Link>
            )}
            {isVisible && (
              <Link
                className={`${
                  breakpoint === "md" || breakpoint === "lg"
                    ? "col-start-5 col-span-2"
                    : "col-start-5"
                } cursor-pointer font-semibold text-md tracking-[.15em] justify-center flex items-center`}
                style={aProposStyle}
                to="/a-propos"
              >
                À PROPOS
              </Link>
            )}
            {isVisible && (
              <Link
                to="/prestations"
                className={`${
                  breakpoint === "md" || breakpoint === "lg"
                    ? "col-span-2"
                    : "col-span-2"
                } cursor-pointer font-semibold text-md tracking-[.15em] justify-center flex items-center`}
                style={prestaStyle}
              >
                NOS PRESTATIONS
              </Link>
            )}
            {isVisible && (
              <Link
                to="/nos-tarifs"
                className={`${
                  breakpoint === "lg"
                    ? "col-span-2"
                    : breakpoint === "md"
                    ? "col-span-3"
                    : "col-span-2"
                } cursor-pointer font-semibold text-md tracking-[.15em] justify-center flex items-center`}
                style={tarifsStyle}
              >
                NOS TARIFS
              </Link>
            )}
            {isVisible && (
              <div
                style={logosStyle}
                className="flex items-center justify-start"
              >
                <a
                  rel="noopener noreferrer"
                  className="cursor-pointer"
                  href="https://www.instagram.com/lalcove/"
                  target="_blank"
                >
                  <img
                    className="mr-3"
                    width={25}
                    src={
                      location.pathname === "/a-propos" ||
                      location.pathname === "/"
                        ? InstagramNude
                        : InstagramPoudre
                    }
                    alt="instagram"
                  />
                </a>
                <a
                  rel="noopener noreferrer"
                  className="cursor-pointer font-semibold tracking-[.15em] color-nude"
                  href="https://fr-fr.facebook.com/lalcove/"
                  target="_blank"
                >
                  <img
                    width={25}
                    src={
                      location.pathname === "/a-propos" ||
                      location.pathname === "/"
                        ? FacebookNude
                        : FacebookPoudre
                    }
                    alt="facebook"
                  />
                </a>
              </div>
            )}
            <button
              id="rdv-btn"
              className={buttonColorClass}
              onClick={takeRdv}
              style={rdvBtnStyle}
            >
              PRENDRE RDV
            </button>
          </div>
        </animated.nav>
      </header>

      {/* Modal */}
      <RendezVous isOpen={isOpen} toggle={toggle}>
        <div className="bg-nude opacity-90 w-3/4 p-14 m-auto shadow-md relative">
          <button
            onClick={toggle}
            className="flex justify-center font-black text-sm align-center font-helvetica color-nude bg-poudre absolute -top-3 -right-3 rounded-full w-6 h-6 leading-6"
          >
            X
          </button>

          <div className="text-center mb-8">
            <span
              className="helvetica-nueu-thin tracking-[.1em]
             font-black text-xl text-center"
            >
              PRENDRE
            </span>{" "}
            <span className="font-parisienne text-4xl color-blue">
              rendez-vous
            </span>
          </div>
          <div className="flex justify-between">
            <div className="flex-col w-1/3 mt-12 ml-10">
              <button
                className="flex justify-center font-semibold tracking-[.1em] text-sm align-center font-helvetica color-nude mb-6 ml-10"
                style={callBtnStyle}
              >
                NOUS APPELER AU 01.46.38.11.17
              </button>
              <div className="color-blue text-center helvetica-nueu-thin tracking-[.1em]">
                Pour nous, rien n'est plus important que le contact humain,
                c'est pour cela que nous privilégions les prises de rendez-vous
                par téléphone.
              </div>
            </div>
            <div className="bg-light w-1 h-52"></div>
            <div className="flex-col w-1/3 mt-12 mr-10">
              <button
                className="flex justify-center font-semibold tracking-[.1em] text-sm align-center font-helvetica color-nude mb-6 ml-10"
                style={planityBtnStyle}
              >
                <a
                  href="https://www.planity.com/lalcove-92140-clamart"
                  rel="noopener noreferrer"
                  target={"_blank"}
                >
                  SUR PLANITY
                </a>
              </button>

              <div className="color-blue text-center helvetica-nueu-thin tracking-[.1em]">
                Vous pouvez aussi prendre rendez-vous directement via notre
                partenaire Planity
              </div>
            </div>
          </div>
        </div>
      </RendezVous>

      {/* Routes elements */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/a-propos" element={<About />} />
        <Route path="/prestations" element={<Benefits />} />
        <Route path="/nos-tarifs" element={<Prices />} />
        <Route path="/mentions-legal" element={<LegalNotice />} />
      </Routes>
    </>
  );
}
