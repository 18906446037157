import React from "react";
import { useSpring, animated } from "@react-spring/web";
import { Link } from "react-router-dom";

export default function TopMobile() {
  const banner = useSpring({
    opacity: 0.95,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(0px, -47rem)" },
    config: { duration: 400, opacity: 0 },
  });

  return (
    <div id="top">
      <div className="wall-img-mobile bg-cover" />
      <div className="flex w-full justify-center relative">
        <animated.div className="absolute -top-20" style={banner}>
          <div
            className="flex justify-center font-helvetica color-blue -mt-20"
            style={{
              backgroundColor: "#d9a08c",
              textAlign: "center",
              width: 340,
              height: 300,
            }}
          >
            <div className="m-auto">
              <div className="text-[24px]">L'ALCÔVE EST UN</div>
              <div className="text-[24px]">ESPACE DÉDIÉ</div>
              <div className="font-parisienne text-[32px] font-bold">
                à la beauté de votre regard
              </div>
              <div className="font-parisienne text-[32px] font-bold">
                et de vos ongles,
              </div>
              <div className="text-[24px]">UN VÉRITABLE COCON</div>
              <div className="text-[24px]">DE SÉRÉNITÉ</div>
            </div>
          </div>
          <div className="flex flex-1 justify-center">
            <button
              className="flex justify-center font-semibold tracking-[.1em] text-sm align-center font-helvetica color-nude"
              style={{
                backgroundColor: "#2e5a6b",
                borderRadius: "20px",
                fontVariant: "all-petite-caps",
                width: 200,
                height: "40px",
                lineHeight: 2,
                marginTop: "-20px",
                fontSize: 18,
              }}
            >
              <Link className="cursor-pointer z-50" to="/prestations-mobile">
                NOS PRESTATIONS
              </Link>
            </button>
          </div>
        </animated.div>
      </div>
    </div>
  );
}
