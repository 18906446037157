import React from "react";
import FacebookBlue from "./../../assets/svg/logo/social/facebook-blue.svg";
import InstagramBlue from "./../../assets/svg/logo/social/instagram-blue.svg";
import GridPhotoMobile from "../../components/grid-photos/gridPhotosMobile";
import ArcheEndroit from "./../../assets/svg/arche/arche-endroit.svg";

export default function FollowUsMobile() {
  return (
    <div id="follow-us" className="h-fit">
      <div className="flex justify-center items-center mt-8">
        <div className="flex mr-5">
          <a
            rel="noopener noreferrer"
            className="cursor-pointer"
            href="https://fr-fr.facebook.com/lalcove/"
            target="_blank"
          >
            <img
              className="mr-3"
              width={35}
              src={FacebookBlue}
              alt="facebook"
            />
          </a>
          <a
            rel="noopener noreferrer"
            className="cursor-pointer"
            href="https://www.instagram.com/lalcove/"
            target="_blank"
          >
            <img width={35} src={InstagramBlue} alt="instagram" />
          </a>
        </div>
        <div className="">
          <span className="font-helvetica tracking-[.1em] text-3xl font-semibold text-center">
            SUIVEZ
          </span>
          <span className="font-parisienne text-6xl">-nous</span>
        </div>
      </div>
      <div className="m-8 relative mb-12">
        <GridPhotoMobile />
        <img
          className="absolute -z-50"
          style={{ top: -106, left: -20 }}
          src={ArcheEndroit}
          width={275}
          alt="archeEndroit"
        />
      </div>
    </div>
  );
}
