const IsRouteExist = (url: string) => {
  // différentes routes possibles :
  const routes = [
    "/", // page d'accueil
    "/a-propos", // page à propos
    "/prestations", // page prestations
    "/nos-tarifs", // page tarifs
    "/mentions-legal", // page mentions légales
    "/menu-mobile", // page menu mobile
    "/a-propos-mobile", // page à propos mobile
    "/prestations-mobile", // page prestations mobile
    "/nos-tarifs-mobile", // page tarifs mobile
    "/mentions-legal-mobile", // page mentions légales mobile
  ];
  return routes.indexOf(url) !== -1;
};

export default IsRouteExist;
