import React, { useState } from "react";

export default function CarouselMobile() {
  // Carousel
  const [current, setCurrent] = useState(0);

  //créer un tableau avec la longueur du carousel avec pour modele le carousel ci dessous
  const carousel = [
    {
      id: 1,
      text: "Je vous recommande cet institut les yeux fermés (c'est le cas de le dire). Caroline est ultra pro, soucieuse de votre bien être et à l'écoute. Le salon est cosy et agréable. Et surtout le résultat esttop ! J ai testé l extension de cils et franchement le résultat est bluffant. Quelle minutie. MERCI CAROLINE",
      name: "- Virginie Dumond",
    },
    {
      id: 2,
      text: "Le service était d’excellente qualité, l’équipe est très agréable, souriante, arrangeante et professionnelle. Mes ongles sont très beaux et l’hygiène est impeccable. Merci à Alicia pour ce super accueil et son professionnalisme, merci à la responsable de sa gentillesse et son adaptation pour me trouver une place rapidement dans leur agenda. Je recommande a 100% !",
      name: "- Cassandra",
    },
    {
      id: 3,
      text: "13 ans que Caroline fait mes extensions de cils ! Le résultat est toujours aussi magnifique avec un rendu naturel. J'adore ! ...",
      name: "- Maryline H",
    },
    {
      id: 4,
      text: "Charmant salon très bien situé dans le centre de Clamart. La qualité des prestations (cils et onglerie) et des techniciennes ont fait de moi une cliente fidèle depuis des années.",
      name: "- Camille P",
    },
    {
      id: 5,
      text: "Je me rends à l'Alcôve faire mes ongles depuis son ouverture et je n'ai jamais été déçue. Les prestations sont de qualités, les techniciennes souriantes et à l'écoute. Le salon est aménagé avec goût. Depuis peu, j'ai craqué et fait également mes extensions de cils. Le résultat est magnifique et le rendu reste naturel. Caroline, perfectionniste, met un point d'honneur à vous magnifier tout en respectant vos envies. Le plus: le salon est irréprochable et respecte toutes les consignes sanitaires (gel hydroalcoolique, aération...). Vous pouvez vous y rendre les yeux fermés!",
      name: "- Anne claire",
    },
    {
      id: 6,
      text: "Je suis cliente depuis l'ouverture à raison d'un rendez-vous tous les 2 mois pour un « réhaut/teinture » des cils et je n'irai ailleurs pour rien au monde, Caroline connaît chacun de ses clients et les chouchoute. Service sur-mesure et qualité de prestation toujours au top, sans oublier une ponctualité très appréciable. ",
      name: "- Stephanie C",
    },
    {
      id: 7,
      text: "Je fais mes extensions de cils et mes ongles à l’Alcôve depuis 5 ans maintenant et je n’ai jamais été déçue! Moi qui suis très perfectionniste je sors toujours de là-bas ravie 😊. Les techniciennes sont adorables et très pro, le salon est agréable et chaleureux, c’est ma deuxième maison maintenant 👌🏼",
      name: "- Laurence A",
    },
  ];

  const length = carousel.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  return (
    <div className="carousel-wrapper-mobile">
      {carousel.map((item, index) => {
        return (
          <div
            key={index}
            className={
              index === current
                ? "carousel-item item-1 first-line:flex-col bg-nude-light p-8 absolute shadow-md color-blue"
                : "hidden"
            }
          >
            <div className="text-center">
              <span className="font-helvetica tracking-[.1em] text-xl font-semibold">
                CE QU’ELLES DISENT DE
              </span>{" "}
              <span className="font-parisienne text-5xl">nous</span>
            </div>
            <div className="flex w-4/5 m-auto h-0.5 bg-blue my-5"></div>
            <p className="text-md helvetica-nueu-thin tracking-[.05em] leading-relaxed items-center">
              <span className="text-md font-semibold">« </span>
              {item.text}
              <span className="text-md font-semibold"> »</span>
              <br />
              <span className="font-parisienne text-xl">{item.name}</span>
            </p>
            <div onClick={prevSlide} className="arrow arrow-prev ml-6" />
            <div onClick={nextSlide} className="arrow arrow-next mr-6" />
          </div>
        );
      })}
    </div>
  );
}
