import React from "react";
import About from "./../../assets/about/about.png";
import About2 from "./../../assets/about/about-2.png";
import About3 from "./../../assets/about/about-3.png";
import About4 from "./../../assets/about/about-4.png";
import ArcheEndroit from "./../../assets/svg/arche/arche-endroit.svg";
import ArcheRetourne from "./../../assets/svg/arche/arche-retourne.svg";
import { useBreakpoint } from "../../utils/useBreakpointLogger";

export default function Presentation() {
  const breakpoint = useBreakpoint();
  const aboutImgStyle = {
    marginTop: breakpoint === "xl" ? -550 : -305,
    marginLeft: breakpoint === "xl" ? "none" : -5,
    transform: "scale(0.6)",
  };
  const boutiqueImgStyle = { height: breakpoint === "xl" ? 1320 : "auto" };
  const caroDivStyle = { height: breakpoint === "xl" ? 900 : 1200 };
  const presentationCaroStyle = {
    width: breakpoint === "xl" ? 750 : 460,
    marginLeft: breakpoint === "xl" ? "none" : breakpoint === "md" ? 30 : 85,
  };
  const aliciaImgStyle = {
    width: breakpoint === "xl" ? 825 : "none",
    marginLeft: breakpoint === "xl" ? 135 : "none",
    marginBottom: breakpoint === "xl" ? "none" : "1rem",
  };
  const presentationAliciaStyle = {
    width: breakpoint === "xl" ? 750 : breakpoint === "md" ? 390 : 460,
    marginLeft:
      breakpoint === "xl" ? "16rem" : breakpoint === "md" ? "2rem" : "5rem",
    marginBottom: breakpoint === "md" ? "3rem" : "",
  };

  return (
    <div id="presentation">
      {/* titre */}
      <div
        className="bg-poudre grid grid-cols-2"
        style={{ height: breakpoint === "md" ? 280 : 430 }}
      >
        <div
          className="col-start-2 h-64 mt-80 -ml-2 color-nude"
          style={{ width: 580 }}
        >
          <span className="font-helvetica tracking-[.1em] text-md font-bold text-2xl">
            INSTALLEZ-VOUS, FERMEZ LES YEUX, ON S’OCCUPE{" "}
          </span>
          <span className="font-parisienne text-6xl">de vous ...</span>
        </div>
      </div>

      {/* content 1 */}
      <div className="grid grid-cols-2" style={{ height: 530 }}>
        <img
          className="-mt-96 mb-10 ml-24 z-50"
          style={aboutImgStyle}
          src={About}
          alt="About"
        />
        <div
          className={`${
            breakpoint === "md" ? "w-4/5" : "w-2/3"
          }  h-80 mt-8 -ml-2`}
        >
          {/* <div className="w-2/3 h-80 mt-8 -ml-2"> */}
          <p className="color-blue helvetica-nueu-thin tracking-[.05em] text-lg">
            <span className="font-semibold">L’Alcôve</span> vous accueille dans
            le quartier de la gare de Clamart dans un cadre chaleureux pensé par
            la talentueuse créatrice d’intérieurs Laurence Auzureau. Nous lui
            avons donné carte blanche pour créer un lieu dans lequel on se sent
            bien, où l’on a envie de revenir souvent. Nous nous sommes reconnues
            dans son sens du détail. Les nuances de roses et de bleus, les
            touches de velours, la rondeur des arches et des luminaires nous
            mettent de bonne humeur pour faire de votre venue une expérience
            singulière, un moment rien qu’à vous.
          </p>
          <br />
          <p className="color-blue helvetica-nueu-thin tracking-[.05em] text-lg">
            Toute l’équipe vous attend avec impatience pour prendre soin de vos
            ongles et de votre regard.
          </p>
        </div>
        <div className="grid grid-cols-2 w-full absolute">
          <div className="ml-10 ">
            <img
              src={ArcheEndroit}
              width={300}
              alt="archeEndroit"
              style={{ marginTop: 97 }}
            />
          </div>
          <div className="flex justify-end mr-10 -mt-5">
            <img
              src={ArcheRetourne}
              width={300}
              alt="archeRetourne"
              style={{ marginBottom: 75 }}
            />
          </div>
        </div>
      </div>

      {/* Photo */}
      <img
        className="mt-14"
        style={boutiqueImgStyle}
        src={About2}
        alt="About2"
      />

      {/* content 2 */}
      <div className="grid grid-cols-2 bg-light" style={caroDivStyle}>
        <img
          src={About3}
          alt="About3"
          className="mt-20"
          style={{ width: 825 }}
        />
        <div className="-ml-20 mt-20" style={presentationCaroStyle}>
          <div className="color-nude">
            <span className="font-helvetica tracking-[.1em] text-md font-bold text-2xl">
              UN ESPACE IMAGINÉ PAR
            </span>
            <span className="font-parisienne text-6xl"> Caroline…</span>
          </div>
          <br />
          <div className="color-nude helvetica-nueu-thin tracking-[.05em] text-lg">
            <p>
              « J’ai créé <span className="font-semibold">L’Alcôve</span> en
              2013 et le salon a ouvert un 14 février. Après plusieurs années à
              sillonner ma ville natale pour m’occuper de vos cils à domicile,
              je voulais vous recevoir dans un lieu chaleureux. Une bulle de
              douceur pour vous permettre de vous échapper du rythme de la vie
              de tous les jours. »
            </p>
            <br />
            <p className="font-semibold">
              Le 14 février, c’est la journée de l’amour et j’ai pensé L’Alcôve
              comme un endroit pour prendre du temps pour soi.
            </p>
            <br />
            <p>
              J’ai découvert l’univers des extensions de cils en 2009 alors que
              je travaillais dans le conseil en image. Le contact humain, le
              côté créatif et manuel, mais aussi le côté technique et minutieux…
              J’ai trouvé ma voie !
            </p>
            <br />
            <p>
              J’ai un coup de cœur immédiat pour ce métier et je me suis tout de
              suite formée.
            </p>
            <br />
            <p>
              Quelques années plus tard, en 2016, j’ai eu la chance de recevoir
              le prix de la 2ème Lash Artist de France lors du concours Golden
              Lash. Mes sources d’inspiration pour ce concours ont été les lash
              artistes slaves auprès desquelles je me forme régulièrement pour
              être à la pointe des nouvelles techniques qui sont en évolution
              permanente.
            </p>
            <br />
            <p>
              Au-delà d’un moment de déconnexion, j’ai à cœur de vous offrir les
              produits les plus naturels et les plus sûrs du marché. Cette
              recherche constante est une des valeurs de{" "}
              <span className="font-semibold">L’Alcôve</span> depuis mes débuts.
              C’est un véritable plaisir d’aller dénicher les dernières
              innovations créatives et de vous les proposer.
            </p>
          </div>
        </div>
      </div>

      {/* content 3 */}
      <div className="grid grid-cols-2 bg-nude">
        <div className="mt-20" style={presentationAliciaStyle}>
          <div className="color-light">
            <span className="font-helvetica tracking-[.1em] text-md font-bold text-2xl">
              ...ET REJOINS PAR
            </span>{" "}
            <span className="font-parisienne text-6xl">Alicia</span>
          </div>
          <p className="color-light helvetica-nueu-thin tracking-[.05em] text-lg">
            « Avant même notre première vraie rencontre, j’ai été séduite par
            les créations nail art de son portfolio. On pouvait deviner à
            travers ses réalisations un travail très soigné. Diplômée en 2020,
            Alicia est passionnée des métiers de la beauté des ongles depuis
            toujours et nous partageons les mêmes valeurs d’amélioration
            constante. Sa grande force est de savoir vous conseiller LA bonne
            couleur, qu’elle soit tendance ou intemporelle, la couleur qui
            s’adapte à votre humeur du moment. Je profite de ces quelques lignes
            pour la remercier de son énergie toujours positive. »
          </p>
        </div>
        <img
          src={About4}
          alt="About4"
          className="my-20"
          style={aliciaImgStyle}
        />
      </div>
    </div>
  );
}
