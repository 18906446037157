import React, { useState } from "react";
import MinusSvg from "../assets/svg/logo/icons/minus.svg";
import PlusSvg from "../assets/svg/logo/icons/plus.svg";
import { Link } from "react-router-dom";
import { useBreakpoint } from "../utils/useBreakpointLogger";

export interface AccordionDataType {
  id: number;
  titlePart1: string;
  titlePart2: string;
  titleContent1: string;
  photoContent1: string;
  content1: string;
  titleContent2: string | null;
  photoContent2: string | null;
  content2: string | null;
  titleContent3: string | null;
  photoContent3: string | null;
  content3: string | null;
}

const Accordion = (props: AccordionDataType) => {
  const [isActive, setIsActive] = useState(false);
  const breakpoint = useBreakpoint();
  const tarifBtnStyle = {
    backgroundColor: "#2e5a6b",
    borderRadius: "20px",
    fontVariant: "all-petite-caps",
    width: "150px",
    height: "40px",
    lineHeight: 2,
    marginTop: "-20px",
    fontSize: 18,
  };

  return (
    <div
      key={props.id}
      className="accordion-item flex flex-col bg-nude w-2/3 items-center m-auto my-6 rounded-xl"
    >
      <div
        className="accordion-title py-3 cursor-pointer"
        onClick={() => setIsActive(!isActive)}
      >
        {/* title */}
        <div
          className={`${
            breakpoint === "lg"
              ? "-ml-[300px]"
              : breakpoint === "md"
              ? "-ml-[230px]"
              : "-ml-[450px]"
          } flex text-md color-blue items-center justify-start my-3`}
        >
          <div className="rounded-full color-nude mt-1 mr-4 w-8 text-center">
            {isActive ? (
              <img src={MinusSvg} alt="minus" />
            ) : (
              <img src={PlusSvg} alt="plus" />
            )}
          </div>
          <span className="font-helvetica tracking-[.1em] font-bold text-2xl mr-2 mt-2">
            {props.titlePart1}
          </span>
          <span className="font-parisienne text-6xl">{props.titlePart2}</span>
        </div>
      </div>
      {isActive && (
        <div className="flex flex-col">
          {/* content1 */}
          <div className="flex mx-16 mb-8 items-center">
            <img width={250} src={props.photoContent1} alt="" />
            <div className="flex flex-col">
              <div className="ml-8">
                <div className="helvetica-nueu-thin tracking-[.1em] font-semibold text-xl">
                  {props.titleContent1}
                </div>
                <div className="mt-3 helvetica-nueu-thin tracking-[.1em]">
                  {props.content1}
                </div>
              </div>

              {/* button */}
              <Link
                to="/nos-tarifs"
                className="flex justify-center mt-12 -ml-36"
                rel="noopener noreferrer"
              >
                <button
                  className="flex justify-center font-semibold tracking-[.1em] text-sm align-center font-helvetica color-nude mb-6"
                  style={tarifBtnStyle}
                >
                  LES TARIFS
                </button>
              </Link>
            </div>
          </div>

          {/* content2 */}
          {props.titleContent2 ? (
            <div className="flex mx-16 mb-8 items-center">
              <div className="flex flex-col">
                <div className="mr-8">
                  <div className="helvetica-nueu-thin tracking-[.1em] font-semibold text-xl">
                    {props.titleContent2}
                  </div>
                  <div className="mt-3 helvetica-nueu-thin tracking-[.1em]">
                    {props.content2}
                  </div>
                </div>

                {/* button */}
                <Link
                  to="/nos-tarifs"
                  className="flex justify-center mt-12 -ml-36"
                  rel="noopener noreferrer"
                >
                  <button
                    className="flex justify-center font-semibold tracking-[.1em] text-sm align-center font-helvetica color-nude mb-6"
                    style={tarifBtnStyle}
                  >
                    LES TARIFS
                  </button>
                </Link>
              </div>
              {props.photoContent2 ? (
                <img width={250} src={props.photoContent2} alt="" />
              ) : null}
            </div>
          ) : null}

          {/* content3 */}
          {props.titleContent3 ? (
            <div className="flex mx-16 mb-8 items-center">
              {props.photoContent3 ? (
                <img width={250} src={props.photoContent3} alt="" />
              ) : null}
              <div className="flex flex-col">
                <div className="ml-8">
                  <div className="helvetica-nueu-thin tracking-[.1em] font-semibold text-xl">
                    {props.titleContent3}
                  </div>
                  <div className="mt-3 helvetica-nueu-thin tracking-[.1em]">
                    {props.content3}
                  </div>
                </div>

                {/* button */}
                <Link
                  to="/nos-tarifs"
                  className="flex justify-center mt-12 -ml-36"
                  rel="noopener noreferrer"
                >
                  <button
                    className="flex justify-center font-semibold tracking-[.1em] text-sm align-center font-helvetica color-nude mb-6"
                    style={tarifBtnStyle}
                  >
                    LES TARIFS
                  </button>
                </Link>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Accordion;
