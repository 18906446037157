import React, { useState } from "react";
import MinusSvg from "../assets/svg/logo/icons/minus.svg";
import PlusSvg from "../assets/svg/logo/icons/plus.svg";
import { Link } from "react-router-dom";

interface AccordionDataType {
  key: number;
  titlePart1: string;
  titlePart2: string;
  titleContent1: string;
  photoContent1: string;
  content1: string;
  titleContent2: string | null;
  photoContent2: string | null;
  content2: string | null;
  titleContent3: string | null;
  photoContent3: string | null;
  content3: string | null;
}

const AccordionMobile = (props: AccordionDataType) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item flex flex-col bg-nude w-11/12 items-center m-auto my-6 rounded-xl">
      <div
        className="accordion-title"
        style={{ width: 280 }}
        onClick={() => setIsActive(!isActive)}
      >
        {/* title */}
        <div className="flex text-md color-blue items-center my-3">
          <div className="rounded-full color-nude cursor-pointer mt-1 mr-4 w-8 text-center">
            {isActive ? (
              <img src={MinusSvg} alt="minus" />
            ) : (
              <img src={PlusSvg} alt="plus" />
            )}
          </div>
          <span className="font-helvetica tracking-[.1em] font-bold text-lg mr-2 mt-2">
            {props.titlePart1}
          </span>
          <span className="font-parisienne text-4xl mt-1">
            {props.titlePart2}
          </span>
        </div>
      </div>
      {isActive && (
        <div key={props.key} className="flex flex-col">
          {/* content1 */}
          <div className="flex flex-col mx-16 mb-8 items-center">
            <img
              width={250}
              src={props.photoContent1}
              className="mb-10"
              alt=""
            />
            <div className="flex flex-col">
              <div>
                <div className="helvetica-nueu-thin tracking-[.1em] font-semibold text-xl">
                  {props.titleContent1}
                </div>
                <div className="mt-3 helvetica-nueu-thin tracking-[.1em]">
                  {props.content1}
                </div>
              </div>

              {/* button */}
              <Link
                to="/nos-tarifs-mobile"
                className="flex justify-center mt-12"
                rel="noopener noreferrer"
              >
                <button
                  className="flex justify-center font-semibold tracking-[.1em] text-sm align-center font-helvetica color-nude mb-6"
                  style={{
                    backgroundColor: "#2e5a6b",
                    borderRadius: "20px",
                    fontVariant: "all-petite-caps",
                    width: "150px",
                    height: "40px",
                    lineHeight: 2,
                    marginTop: "-20px",
                    fontSize: 18,
                  }}
                >
                  LES TARIFS
                </button>
              </Link>
            </div>
          </div>

          {/* content2 */}
          {props.titleContent2 ? (
            <div className="flex mx-16 mb-8 items-center">
              <div className="flex flex-col">
                {props.photoContent2 ? (
                  <img
                    width={250}
                    src={props.photoContent2}
                    alt=""
                    className="mb-10"
                  />
                ) : null}

                <div>
                  <div className="helvetica-nueu-thin tracking-[.1em] font-semibold text-xl">
                    {props.titleContent2}
                  </div>
                  <div className="mt-3 helvetica-nueu-thin tracking-[.1em]">
                    {props.content2}
                  </div>
                </div>

                {/* button */}
                <Link
                  to="/nos-tarifs-mobile"
                  className="flex justify-center mt-12"
                  rel="noopener noreferrer"
                >
                  <button
                    className="flex justify-center font-semibold tracking-[.1em] text-sm align-center font-helvetica color-nude mb-6"
                    style={{
                      backgroundColor: "#2e5a6b",
                      borderRadius: "20px",
                      fontVariant: "all-petite-caps",
                      width: "150px",
                      height: "40px",
                      lineHeight: 2,
                      marginTop: "-20px",
                      fontSize: 18,
                    }}
                  >
                    LES TARIFS
                  </button>
                </Link>
              </div>
            </div>
          ) : null}
          {/* content3 */}
          {props.titleContent3 ? (
            <div className="flex flex-col mx-16 mb-8 items-center">
              {props.photoContent3 ? (
                <img
                  width={250}
                  src={props.photoContent3}
                  alt=""
                  className="mb-10"
                />
              ) : null}
              <div className="flex flex-col">
                <div>
                  <div className="helvetica-nueu-thin tracking-[.1em] font-semibold text-xl">
                    {props.titleContent3}
                  </div>
                  <div className="mt-3 helvetica-nueu-thin tracking-[.1em]">
                    {props.content3}
                  </div>
                </div>

                {/* button */}
                <Link
                  to="/nos-tarifs-mobile"
                  className="flex justify-center mt-12"
                  rel="noopener noreferrer"
                >
                  <button
                    className="flex justify-center font-semibold tracking-[.1em] text-sm align-center font-helvetica color-nude mb-6"
                    style={{
                      backgroundColor: "#2e5a6b",
                      borderRadius: "20px",
                      fontVariant: "all-petite-caps",
                      width: "150px",
                      height: "40px",
                      lineHeight: 2,
                      marginTop: "-20px",
                      fontSize: 18,
                    }}
                  >
                    LES TARIFS
                  </button>
                </Link>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default AccordionMobile;
