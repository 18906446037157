import React from "react";
import { useSpring, animated } from "@react-spring/web";
import { Link } from "react-router-dom";
import { useBreakpoint } from "../../utils/useBreakpointLogger";

export default function Top() {
  const breakpoint = useBreakpoint();

  const banner = useSpring({
    opacity: 0.95,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(0px, -47rem)" },
    config: { duration: 400, opacity: 0 },
  });

  const prestationImg = {
    height: breakpoint === "md" || breakpoint === "lg" ? "390px" : "510px",
  };
  const prestationBtn = {
    backgroundColor: "#2e5a6b",
    borderRadius: "20px",
    fontVariant: "all-petite-caps",
    width: "330px",
    height: "40px",
    lineHeight: 2,
    marginTop: breakpoint === "md" || breakpoint === "lg" ? "-50px" : "-20px",
    fontSize: 18,
    transform:
      breakpoint === "md" || breakpoint === "lg" ? "scale(.75)" : "none",
  };
  const animInnerDiv = {
    backgroundColor: "#d9a08c",
    transform:
      breakpoint === "md" || breakpoint === "lg" ? "scale(.75)" : "none",
  };

  return (
    <div id="top">
      <div style={prestationImg} className="wall-img bg-cover" />
      <div className="flex w-full justify-center relative">
        <animated.div className="absolute -top-20 z-50" style={banner}>
          <div
            className="flex justify-center font-helvetica text-xl px-4 py-2 color-blue h-[12.938rem] -mt-[4.688rem] w-[79.438rem]"
            style={animInnerDiv}
          >
            <p className="mx-12 m-auto text-[35px] leading-[3rem] text-center">
              L'ALCÔVE EST UN ESPACE DÉDIÉ
              <span className="font-parisienne text-[56px]">
                {" "}
                à la beauté de votre regard <br />
                et de vos ongles
              </span>
              , UN VÉRITABLE COCON DE SÉRÉNITÉ
            </p>
          </div>
          <div className="flex flex-1 justify-center">
            <button
              className="flex justify-center font-semibold tracking-[.1em] text-sm align-center font-helvetica color-nude"
              style={prestationBtn}
            >
              <Link className="cursor-pointer z-50" to="/prestations">
                DÉCOUVREZ NOS PRESTATIONS
              </Link>
            </button>
          </div>
        </animated.div>
      </div>
    </div>
  );
}
