import React from "react";
import { Link } from "react-router-dom";

export default function MenuMobile() {
  return (
    <>
      <menu className="bg-poudre color-nude" style={{ height: "76vh" }}>
        <div
          className="flex flex-col"
          style={{
            paddingTop: "35vh",
          }}
        >
          <Link
            className="font-semibold text-md tracking-[.15em] justify-center flex items-center helvetica-nueu-thin mx-auto mb-3 rounded-full px-8"
            to="/"
            style={{
              backgroundColor: "#2e5a6b",
              height: "50px",
              lineHeight: 2,
              fontSize: 18,
            }}
          >
            ACCUEIL
          </Link>
          <Link
            className="font-semibold text-md tracking-[.15em] justify-center flex items-center helvetica-nueu-thin mx-auto mb-3 rounded-full px-8"
            to="/a-propos-mobile"
            style={{
              backgroundColor: "#2e5a6b",
              height: "50px",
              lineHeight: 2,
              fontSize: 18,
            }}
          >
            À PROPOS
          </Link>
          <Link
            className="font-semibold text-md tracking-[.15em] justify-center flex items-center helvetica-nueu-thin mx-auto mb-3 rounded-full px-8"
            to="/prestations-mobile"
            style={{
              backgroundColor: "#2e5a6b",
              height: "50px",
              lineHeight: 2,
              fontSize: 18,
            }}
          >
            NOS PRESTATION
          </Link>
          <Link
            className="font-semibold text-md tracking-[.15em] justify-center flex items-center helvetica-nueu-thin mx-auto rounded-full px-8"
            to="/nos-tarifs-mobile"
            style={{
              backgroundColor: "#2e5a6b",
              height: "50px",
              lineHeight: 2,
              fontSize: 18,
            }}
          >
            NOS TARIFS
          </Link>
        </div>
      </menu>
    </>
  );
}
