import React from "react";
import CarouselMobile from "../../components/carousel/carouselMobile";

export default function WhatTheySayMobile() {
  return (
    <div id="what-they-say" className="mud-img-mobile flex justify-center">
      <CarouselMobile />
    </div>
  );
}
