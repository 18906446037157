import React from "react";
import { useSpring, animated } from "@react-spring/web";
import ArcheEndroit from "./../../assets/svg/arche/arche-endroit.svg";
import ArcheRetourne from "./../../assets/svg/arche/arche-retourne.svg";
import Cils from "./../../assets/img/beaute_des_cils.png";
import Sourcils from "./../../assets/img/beaute_des_sourcils.png";
import Ongles from "./../../assets/img/beaute_des_ongles.png";
import Soins from "./../../assets/img/nos_soins.png";

export default function Discover() {
  const arch = useSpring({
    width: 200,
    height: 260,
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(0px, 10rem)" },
    config: { duration: 400, opacity: 0 },
  });

  return (
    <div id="discover" className="relative z-0">
      <div className="grid grid-cols-4 justify-items-center">
        <div className="mt-20">
          <img src={ArcheEndroit} width={300} alt="archeEndroit" />
        </div>
        <div className="col-end-5">
          <img src={ArcheRetourne} width={300} alt="archeRetourne" />
        </div>
      </div>
      <div className="grid grid-cols-6 gap-56 justify-items-center absolute top-32">
        <animated.div style={arch} className="col-start-2">
          <img
            className="absolute rounded-tl-full rounded-tr-full shadow-md h-52"
            width={200}
            src={Cils}
            alt="Cils"
          />
          <div className="flex justify-center items-center text-center font-helvetica text-lg bg-poudre color-nude tracking-[.1em] py-1 h-20 bottom-0 absolute w-full">
            BEAUTÉ <br />
            DES CILS
          </div>
        </animated.div>
        <animated.div style={arch}>
          <img
            className="absolute rounded-tl-full rounded-tr-full shadow-md h-52"
            width={200}
            src={Sourcils}
            alt="Sourcils"
          />
          <div className="flex justify-center items-center text-center font-helvetica text-lg bg-poudre color-nude tracking-[.1em] py-1 h-20 bottom-0 absolute w-full">
            BEAUTÉ <br />
            DES SOURCILS
          </div>
        </animated.div>
        <animated.div style={arch}>
          <img
            className="absolute rounded-tl-full rounded-tr-full shadow-md h-52"
            width={200}
            src={Ongles}
            alt="Ongles"
          />
          <div className="flex justify-center items-center text-center font-helvetica text-lg bg-poudre color-nude tracking-[.1em] py-1 h-20 bottom-0 absolute w-full">
            BEAUTÉ <br />
            DES ONGLES
          </div>
        </animated.div>
        <animated.div style={arch}>
          <img
            className="absolute rounded-tl-full rounded-tr-full shadow-md h-52"
            width={200}
            src={Soins}
            alt="Soins"
          />
          <div className="flex justify-center items-center text-center font-helvetica text-lg bg-poudre color-nude tracking-[.1em] py-1 h-20 bottom-0 absolute w-full">
            NOS SOINS
          </div>
        </animated.div>
      </div>
    </div>
  );
}
