import React from "react";
import Top from "./home/Top";
import TopMobile from "./home/TopMobile";
import Discover from "./home/Discover";
import DiscoverMobile from "./home/DiscoverMobile";
import Presentation from "./home/Presentation";
import PresentationMobile from "./home/PresentationMobile";
import WhatTheySay from "./home/WhatTheySay";
import WhatTheySayMobile from "./home/WhatTheySayMobile";
import FollowUs from "./home/FollowUs";
import FollowUsMobile from "./home/FollowUsMobile";
import { BrowserView, MobileView } from "react-device-detect";

export default function Home() {
  return (
    <>
      <BrowserView>
        <article>
          <Top />
          <Discover />
          <Presentation />
          <WhatTheySay />
          <FollowUs />
        </article>
      </BrowserView>
      <MobileView>
        <article>
          <TopMobile />
          <DiscoverMobile />
          <PresentationMobile />
          <WhatTheySayMobile />
          <FollowUsMobile />
        </article>
      </MobileView>
    </>
  );
}
