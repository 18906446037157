import React, { useState } from "react";
import FacebookBlue from "./../assets/svg/logo/social/facebook-blue.svg";
import InstagramBlue from "./../assets/svg/logo/social/instagram-blue.svg";
import LalcoveSoloNameBlueBlack from "./../assets/svg/logo/logo_blue_black/logo_nom_solo_blue_black.svg";
import LalcoveLogoBlueBlack from "./../assets/svg/logo/logo_blue_black/logo_sans_nom_blue_black.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";

export default function Footer() {
  const location = useLocation();
  const [show, setShow] = useState(false);

  // const isShow = () => {
  //   if (location.pathname === "/menu-mobile") {
  //     setShow(false);
  //   }
  //   if (location.pathname === "/rendez-vous-mobile") {
  //     setShow(false);
  //   } else {
  //     setShow(true);
  //   }
  // };
  // isShow();
  return (
    <>
      <footer
        className="bg-poudre relative z-0"
        style={{
          marginBottom: `${
            location.pathname === "/menu-mobile" ? "-28rem" : ""
          }`,
        }}
      >
        <div className="flex-col justify-center py-12 px-5">
          {location.pathname !== "/menu-mobile" && (
            <img
              src={LalcoveLogoBlueBlack}
              alt="lalcoveSoloNameNude"
              className="w-32 m-auto"
            />
          )}
          {location.pathname !== "/menu-mobile" && (
            <img
              src={LalcoveSoloNameBlueBlack}
              alt="lalcoveSoloNameNude"
              className="w-32 m-auto mb-6"
            />
          )}
          {show && (
            // {location.pathname !== "/menu-mobile" && (
            <div className="color-blue text-center font-helvetica">
              <Link
                rel="noopener noreferrer"
                target="_blank"
                to="https://www.google.com/maps/place/L'ALC%C3%94VE/@48.8125068,2.2710927,17z/data=!3m1!4b1!4m6!3m5!1s0x47e67a7f2b67303d:0xdb6205a4a37f39dc!8m2!3d48.8125068!4d2.2710927!16s%2Fg%2F11btmshb4x"
              >
                <div className="font-semibold text-xl tracking-[.03em]">
                  BEAUTÉ DES CILS ET BEAUTÉ DES ONGLES
                </div>
                <div className="text-xl">
                  229 AVENUE JEAN JAURÈS // 92140 CLAMART
                </div>
                <div className="text-2xl">
                  <a href="tel:01 46 38 11 17">01 46 38 11 17</a>
                </div>
              </Link>
            </div>
          )}
          <div className="flex my-4 justify-center">
            <a
              className="cursor-pointer w-10"
              href="https://www.instagram.com/lalcove/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img width={30} src={InstagramBlue} alt="instagram" />
            </a>
            <a
              className="cursor-pointer w-10"
              href="https://fr-fr.facebook.com/lalcove/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img width={30} src={FacebookBlue} alt="facebook" />
            </a>
          </div>
          <div className="text-md cursor-pointer underline text-center helvetica-nueu-thin">
            <Link to={isMobile ? "/mentions-legal-mobile" : "/mentions-legal"}>
              Mentions légales
            </Link>
          </div>

          <div className="text-md cursor-pointer underline text-center helvetica-nueu-thin">
            <Link
              rel="noopener noreferrer"
              target="_blank"
              to="https://www.google.com/maps/place/L'ALC%C3%94VE/@48.8125068,2.2710927,17z/data=!3m1!4b1!4m6!3m5!1s0x47e67a7f2b67303d:0xdb6205a4a37f39dc!8m2!3d48.8125068!4d2.2710927!16s%2Fg%2F11btmshb4x"
            >
              Nous trouver
            </Link>
          </div>
        </div>
      </footer>
    </>
  );
}
