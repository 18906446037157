import React from "react";
import Page404Svg from "./../assets/svg/img/404.svg";
import { Link } from "react-router-dom";

export default function Page404() {
  return (
    <article className="flex flex-1 justify-center items-center m-auto h-full">
      <img src={Page404Svg} alt="instagram" />
      <div className="flex-col ml-8">
        <p className="font-parisienne text-9xl color-light mb-5">Oups...</p>
        <p className="mb-1">Cette page n'existe pas...</p>
        <p className="mb-3">Mais vous vouliez peut-être :</p>

        <div
          className="flex justify-center font-semibold tracking-[.1em] text-sm align-center font-helvetica color-nude mb-1"
          style={{
            backgroundColor: "#2e5a6b",
            borderRadius: "20px",
            fontVariant: "all-petite-caps",
            width: "330px",
            height: "40px",
            lineHeight: 2,
            fontSize: 18,
          }}
        >
          <a
            href="https://www.planity.com/lalcove-92140-clamart"
            rel="noopener noreferrer"
            target="_blank"
          >
            PRENDRE RDV
          </a>
        </div>

        <Link
          to="/prestations"
          target="_blank"
          className="flex justify-center font-semibold tracking-[.1em] text-sm align-center font-helvetica color-nude mt-2"
          style={{
            backgroundColor: "#2e5a6b",
            borderRadius: "20px",
            fontVariant: "all-petite-caps",
            width: "330px",
            height: "40px",
            lineHeight: 2,
            fontSize: 18,
          }}
        >
          DÉCOUVRIR NOS PRESTATIONS
        </Link>

        <Link
          to="/nos-tarifs"
          target="_blank"
          className="flex justify-center font-semibold tracking-[.1em] text-sm align-center font-helvetica color-nude mt-2"
          style={{
            backgroundColor: "#2e5a6b",
            borderRadius: "20px",
            fontVariant: "all-petite-caps",
            width: "330px",
            height: "40px",
            lineHeight: 2,
            fontSize: 18,
          }}
        >
          CONNAÎTRE NOS TARIFS
        </Link>
      </div>
    </article>
  );
}
