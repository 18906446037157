import Accordion, { AccordionDataType } from "../components/Accordion";
import Cils from "../assets/img/beaute_des_cils_presta.png";
import VolumeRusse from "../assets/img/volume_russe.png";
import RehauCils from "../assets/img/rehau.png";
import Sourcils from "../assets/img/beaute_des_sourcils_arche.png";
import Gainage from "../assets/img/gainage.png";
import Ralongement from "../assets/img/ralongement.png";
import Semi from "../assets/img/semi.png";
import Soins from "../assets/img/nos_soins.png";
import CartesCadeau from "../assets/img/cartes_cadeau.png";

export default function Benefits() {
  const accordionData: AccordionDataType[] = [
    {
      id: 1,
      titlePart1: "BEAUTÉ DES",
      titlePart2: "cils",
      photoContent1: Cils,
      titleContent1: "LES EXTENSIONS CIL A CIL",
      content1: `Vous souhaitez vous passer de mascara et obtenir un regard souligné ?
      Découvrez notre expertise en pose d'extensions de cils méthode cil à cil, une technique minutieuse où chaque extension est 
      appliquée individuellement sur le cil naturel, sans contact avec la peau. Avant la prestation, un questionnaire détaillé est 
      rempli pour comprendre vos besoins et habitudes. Notre méthode assure un résultat naturel, mettant en valeur votre regard de 
      manière subtile et élégante. Nous personnalisons chaque résultat en sélectionnant différentes longueurs, courbures et épaisseurs 
      d'extensions en fonction de la forme de votre œil, la base de vos cils et bien-sûr vos souhaits. Réservez dès maintenant pour un 
      regard magnifié en toute sécurité et avec un résultat sur mesure !`,
      photoContent2: VolumeRusse,
      titleContent2: "LES EXTENSIONS VOLUME RUSSE",
      content2: `Encore plus de volume sur vos cils ? Ou bien une ligne de cils subtile et élégante ? Le volume russe est la réponse.
      Il s'agit d'une technique sophistiquée qui consiste à appliquer des bouquets de cils, démultipliant ainsi la quantité de cils 
      pour un volume spectaculaire ou a l'inverse une mise en valeur légère. Chaque bouquet est confectionné à la main, garantissant 
      un résultat sur mesure et un effet parfaitement adapté à vos désirs. Cette méthode offre une variété de possibilités en termes 
      d'intensité de volume. Nos techniciennes hautement qualifiées maîtrisent les techniques complexes nécessaires pour obtenir un 
      résultat impeccable. Réservez dès maintenant pour des cils magnifiquement mis en valeur !`,
      photoContent3: RehauCils,
      titleContent3: "REHAUSSEMENT DE CILS",
      content3: `Juste un embellissement naturel de vos cils grace à cette méthode.
      Le rehaussement est une technique innovante pour donner à vos cils une courbure naturelle. Aucun ajout sur votre cil, seule sa 
      courbure est modifiée, offrant un résultat naturel et visible. Notre méthode utilise des produits de haute qualité et des techniques
       expertes pour soulever délicatement vos cils dès la racine, offrant un regard plus ouvert et éveillé. De plus, pour intensifier 
       encore le résultat, nous proposons une teinture de cils à la suite du rehaussement. Cette combinaison offre un effet durable et 
       subtil, mettant en valeur votre regard de manière remarquable. Réservez dès maintenant pour des cils parfaitement courbés et 
       intensément teintés !`,
    },
    {
      id: 2,
      titlePart1: "BEAUTÉ DES",
      titlePart2: "ongles",
      photoContent1: Ralongement,
      titleContent1: "RALLONGEMENT AU GEL",
      content1: `Vous souhaitez avoir des ongles plus longs cette prestation est faite pour vous !
      Nous utilisons la méthode du chablon qui permet de créer un ongle sur mesure au gel à l'aide d'un papier forme 
      que l'on place à l'extrémité 
      de votre ongle naturel en guise de support. Ce support sert de guide pour modeler le gel en vue d'une extension. 
      Cette technique permet de travailler en finesse et d'avoir un rendu naturel.
      Le gel offre également une certaine flexibilité, ce qui les rend moins susceptibles
      de se casser ou de s'écailler par rapport à d'autres techniques.
      Cette prestation comprend la préparation de l'ongle dite manucure qui consiste à mettre en forme vos ongles et à couper vos cuticules
      ainsi que la pose d'une couleur ou d'une french.`,
      photoContent2: Gainage,
      titleContent2: "GAINAGE",
      content2: `Vous avez les ongles mous et cassants cette prestation est faite pour vous !
      Le gainage, aussi appelé renforcement, est une technique qui consiste à solidifier l'ongle naturel avec du gel
      puis à appliquer un vernis semi-permanent ou une french. L'objectif étant de rendre vos ongles plus solides et de les mettre en 
      beauté pour une durée de 3 semaines. Cette prestation comprend la préparation de l'ongle dite manucure qui
      consiste à mettre en forme vos ongles et à couper vos cuticules.`,
      photoContent3: Semi,
      titleContent3: "VERNIS SEMI-PERMANENT",
      content3: `Vous voulez un vernis qui ne s'écaille pas, avec une brillance éclatante ?
      Nous vous proposons la pose de vernis semi-permanent, il s'applique de la même manière qu'un vernis classique mais 
      l'avantage est qu'il sèche instantanément en 60s sous lampe led et qu'il met en beauté vos ongles pour une durée de 3 semaines.
      Son retrait doit être fait avec précaution pour préserver la santé des ongles.
      Cette prestation comprend la preparation de l'ongle dite manucure qui consiste à mettre en forme vos ongles et à couper vos cuticules.`,
    },
    {
      id: 3,
      titlePart1: "BEAUTÉ DES",
      titlePart2: "sourcils",
      photoContent1: Sourcils,
      titleContent1: "SOURCILS",
      content1: `Pour mettre en valeur un regard, la définition de la ligne des sourcils est une étape importante et vous pouvez nous confier
      cette mission. 
      Différentes techniques vous seront proposées en fonction de vos besoins. La restructuration des sourcils, la teinture hybride ou encore
      le browlift sont des références pour dessiner des sourcils naturels et parfaitement adaptés à la forme de votre visage.`,
      titleContent2: null,
      photoContent2: null,
      content2: null,
      titleContent3: null,
      photoContent3: null,
      content3: null,
    },
    {
      id: 4,
      titlePart1: "NOS",
      titlePart2: "soins",
      photoContent1: Soins,
      titleContent1: "SOINS",
      content1: `Bienvenue dans notre espace dédié aux soins des mains et des pieds, où douceur et beauté sont au rendez-vous. 
      Nos traitements spécialisés sont conçus pour gommer délicatement les impuretés, hydrater profondément la peau et combattre 
      efficacement les callosités, révélant ainsi la douceur sous-jacente de vos mains et de vos pieds.`,
      titleContent2: null,
      photoContent2: null,
      content2: null,
      titleContent3: null,
      photoContent3: null,
      content3: null,
    },
  ];
  return (
    <>
      {/* top */}
      <article className="color-blue">
        <div className="justify-center flex text-md mt-56 items-center w-full">
          <span className="font-helvetica tracking-[.1em] text-md font-bold text-2xl mr-2">
            Découvrez
          </span>
          <span className="font-parisienne text-6xl">nos prestations</span>
        </div>
        <div className="helvetica-nueu-thin tracking-[.05em] text-lg flex justify-center font-semibold mb-10">
          Toutes nos prestations sont délivrées par des techniciennes diplômées
          et passionnées par leur métier
        </div>
      </article>

      {/* accordeon */}
      <article className="bg-poudre p-1">
        {accordionData.map((item: AccordionDataType) => (
          <Accordion
            id={item.id}
            titlePart1={item.titlePart1}
            titlePart2={item.titlePart2}
            photoContent1={item.photoContent1}
            titleContent1={item.titleContent1}
            content1={item.content1}
            photoContent2={item.photoContent2}
            titleContent2={item.titleContent2}
            content2={item.content2}
            photoContent3={item.photoContent3}
            titleContent3={item.titleContent3}
            content3={item.content3}
          />
        ))}
      </article>

      {/* bottom */}
      <article className="bg-light color-nude grid grid-cols-2 items-center h-96 py-12">
        <div className="flex flex-col ml-80 my-auto">
          <div>
            <span className="font-helvetica tracking-[.1em] text-md font-bold text-2xl mr-1">
              OFFREZ NOS
            </span>
            <span className="font-parisienne text-6xl ml-2">prestations</span>
          </div>
          <div className="helvetica-nueu-thin tracking-[.1em]">
            Pour offrir et faire plaisir, L’Alcôve propose la carte cadeau.
          </div>
        </div>
        <img className="ml-48 h-64" src={CartesCadeau} alt="CartesCadeau" />
      </article>
    </>
  );
}
