import React from "react";
import Interior from "./../../assets/img/interior.png";
import { Link } from "react-router-dom";
import { useBreakpoint } from "../../utils/useBreakpointLogger";

export default function Presentation() {
  const breakpoint = useBreakpoint();
  const imgStyle = {
    transform: breakpoint === "xl" ? "scale(2)" : "scale(3)",
    marginLeft: breakpoint !== "xl" ? 85 : "none",
    marginTop: breakpoint !== "xl" ? 65 : "none",
  };
  return (
    <div id="presentation">
      <div className="bg-light grid grid-cols-8 justify-items-center z-10">
        <div className="flex-col text-xl color-nude py-20 col-start-2 col-end-5">
          <div>
            <span className="helvetica-nueu-thin tracking-[.1em] text-3xl">
              AU CŒUR DE CLAMART, UN MOMENT RIEN QU’À VOUS
            </span>{" "}
            <span className="font-parisienne text-6xl">rien qu’à vous.</span>
          </div>
          <br />
          <p className="helvetica-nueu-thin tracking-[.05em] mb-16">
            L’Alcôve est un institut de beauté contemporain dédié au soin de vos
            cils, de vos sourcils et de vos ongles. Caroline et Alicia vous
            accueillent à Clamart du lundi au samedi pour vous proposer : pose
            de vernis semi- permanent ou gel, extensions de cils, volume russe,
            un rehaussement ou une teinture. Nous aimons particulièrement vous
            conseiller et nous nous adaptons toujours à vos envies du moment.
            Nous sommes passionnées par les métiers de la beauté et du soin et
            nous suivons les dernières tendances avec attention. Appelez-nous
            pour en savoir plus ou passez nous voir, c’est toujours un plaisir
            d’échanger avec vous.
          </p>
        </div>
        <div
          className="col-end-7"
          style={{ marginTop: breakpoint === "xl" ? "1rem" : "3rem" }}
        >
          <img
            alt="Wall"
            className="rounded-tl-full rounded-tr-full"
            style={imgStyle}
            src={Interior}
          />
        </div>
      </div>
      <div className="flex flex-1 justify-center -mt-5">
        <button
          className="flex justify-center tracking-[.1em] text-sm align-center font-helvetica bg-poudre color-nude rounded-full"
          style={{
            width: 270,
            height: 45,
            marginBottom: -20,
          }}
        >
          <Link
            to="/a-propos"
            className="text-2xl"
            style={{
              lineHeight: 2,
            }}
          >
            NOTRE HISTOIRE
          </Link>
        </button>
      </div>
    </div>
  );
}
