import React from "react";
import "./App.css";
import Footer from "./components/footer";
import Navbar from "./components/navbar/navbar";
import ScrollToTopBtn from "./components/ScrollToTopBtn";
import { BrowserRouter as Router } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import NavbarMobile from "./components/navbar/navbarMobile";
import ScrollToTop from "./components/ScrollToTop";
import Page404 from "./pages/404";
import Page404Mobile from "./pages/404Mobile";
import IsRouteExist from "./components/IsRouteExist";
import { useBreakpoint } from "./utils/useBreakpointLogger";

function App(): JSX.Element {
  const url = window.location.pathname;
  const isRouteExist = IsRouteExist(url);
  const breakpoint = useBreakpoint();
  console.log(breakpoint);

  const isDesktop =
    breakpoint === "xl" || breakpoint === "lg" || breakpoint === "md";
  const isMobile = breakpoint === "sm" || breakpoint === "xs";

  return (
    <Router>
      <ScrollToTop />
      <ScrollToTopBtn />
      {isDesktop && (
        <BrowserView>
          <Navbar />
          {isRouteExist ? <Footer /> : <Page404 />}
        </BrowserView>
      )}
      {isMobile && (
        <MobileView>
          <NavbarMobile />
          {isRouteExist ? <Footer /> : <Page404Mobile />}
        </MobileView>
      )}
    </Router>
  );
}

export default App;
