import React from "react";
import Page404Svg from "./../assets/svg/img/404.svg";
import { Link } from "react-router-dom";

export default function Page404Mobile() {
  return (
    <article className="flex flex-1 justify-center items-center">
      <div className="flex-col">
        <img
          src={Page404Svg}
          style={{ transform: "scale(0.5)" }}
          alt="instagram"
        />
        <p className="flex justify-center font-parisienne text-9xl color-light mb-5 -mt-32">
          Oups...
        </p>
        <p className="flex justify-center mb-1">Cette page n'existe pas...</p>
        <p className="flex justify-center mb-3">
          Mais vous vouliez peut-être :
        </p>

        <div className="flex flex-col flex-1 justify-center items-center">
          <div
            className="flex justify-center font-semibold tracking-[.1em] text-sm align-center font-helvetica color-nude mb-1"
            style={{
              backgroundColor: "#2e5a6b",
              borderRadius: "20px",
              fontVariant: "all-petite-caps",
              width: "300px",
              height: "40px",
              lineHeight: 2,
              fontSize: 18,
            }}
          >
            <a
              href="https://www.planity.com/lalcove-92140-clamart"
              rel="noopener noreferrer"
              target="_blank"
            >
              PRENDRE RDV
            </a>
          </div>
          <Link
            to="/prestations-mobile"
            target="_blank"
            className="flex justify-center font-semibold tracking-[.1em] text-sm align-center font-helvetica color-nude mt-2"
            style={{
              backgroundColor: "#2e5a6b",
              borderRadius: "20px",
              fontVariant: "all-petite-caps",
              width: "300px",
              height: "40px",
              lineHeight: 2,
              fontSize: 18,
            }}
          >
            DÉCOUVRIR NOS PRESTATIONS
          </Link>

          <Link
            to="/nos-tarifs-mobile"
            target="_blank"
            className="flex justify-center font-semibold tracking-[.1em] text-sm align-center font-helvetica color-nude mt-2 mb-10"
            style={{
              backgroundColor: "#2e5a6b",
              borderRadius: "20px",
              fontVariant: "all-petite-caps",
              width: "300px",
              height: "40px",
              lineHeight: 2,
              fontSize: 18,
            }}
          >
            CONNAÎTRE NOS TARIFS
          </Link>
        </div>
      </div>
    </article>
  );
}
