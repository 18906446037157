import React from "react";

export default function RendezVousMobile() {
  return (
    <div className="flex flex-col bg-nude p-14 m-auto shadow-md relative">
      <div className="text-center mb-8 mt-12">
        <span className="helvetica-nueu-thin tracking-[.1em] font-black text-xl text-center">
          PRENDRE
        </span>{" "}
        <span className="font-parisienne text-4xl color-blue">rendez-vous</span>
      </div>
      <div className="flex justify-center">
        <button
          className="font-semibold tracking-[.1em] text-sm align-center font-helvetica color-nude mb-6"
          style={{
            backgroundColor: "#2e5a6b",
            borderRadius: 20,
            fontVariant: "all-petite-caps",
            width: 220,
            height: 40,
            lineHeight: 2,
            fontSize: 18,
          }}
        >
          <a href="tel:01 46 38 11 17">NOUS APPELER</a>
        </button>
      </div>
      <div className="color-blue text-center helvetica-nueu-thin tracking-[.1em]">
        Pour nous, rien n'est plus important que le contact humain,c'est pour
        cela que nous privilégions les prises de rendrez-vous par téléphone.
      </div>
      <div className="flex justify-center mt-12">
        <button
          className="font-semibold tracking-[.1em] text-sm align-center font-helvetica color-nude mb-6"
          style={{
            backgroundColor: "#2e5a6b",
            borderRadius: 20,
            fontVariant: "all-petite-caps",
            width: 220,
            height: 40,
            lineHeight: 2,
            fontSize: 18,
          }}
        >
          <a
            href="https://www.planity.com/lalcove-92140-clamart"
            rel="noopener noreferrer"
            target={"_blank"}
          >
            SUR PLANITY
          </a>
        </button>
      </div>

      <div className="color-blue text-center helvetica-nueu-thin tracking-[.1em]">
        Vous pouvez aussi prendre rendez-vous directement via notre partenaire
        Planity
      </div>
    </div>
  );
}
