import React from "react";
import Follow1 from "./../../assets/img/follow_1.png";
import Follow2 from "./../../assets/img/follow_2.png";
import Follow3 from "./../../assets/img/follow_3.png";
import Follow4 from "./../../assets/img/follow_4.png";
import Follow5 from "./../../assets/img/follow_5.png";
import Follow6 from "./../../assets/img/follow_6.png";
import Follow7 from "./../../assets/img/follow_7.png";
import Follow8 from "./../../assets/img/follow_8.png";
import Follow9 from "./../../assets/img/follow_9.png";
import Follow10 from "./../../assets/img/follow_10.png";
import Follow11 from "./../../assets/img/follow_11.png";
import Follow12 from "./../../assets/img/follow_12.png";
import Follow13 from "./../../assets/img/follow_13.png";
import Follow14 from "./../../assets/img/follow_14.png";
import Follow15 from "./../../assets/img/follow_15.png";
import Follow16 from "./../../assets/img/follow_16.png";
import Follow17 from "./../../assets/img/follow_17.png";
import Follow18 from "./../../assets/img/follow_18.png";
import Follow19 from "./../../assets/img/follow_19.png";
import Follow20 from "./../../assets/img/follow_20.png";
import Follow21 from "./../../assets/img/follow_21.png";
import Follow23 from "./../../assets/img/follow_22.png";
import Follow22 from "./../../assets/img/follow_23.png";

function getMultipleRandom(num: number) {
  const shuffled = [
    Follow1,
    Follow2,
    Follow3,
    Follow4,
    Follow5,
    Follow6,
    Follow7,
    Follow8,
    Follow9,
    Follow10,
    Follow11,
    Follow12,
    Follow13,
    Follow14,
    Follow15,
    Follow16,
    Follow17,
    Follow18,
    Follow19,
    Follow20,
    Follow21,
    Follow22,
    Follow23,
  ].sort(() => 0.5 - Math.random());

  return shuffled.slice(0, num);
}

export default function GridPhoto() {
  let photosToShow = getMultipleRandom(9);

  return (
    <section className="post-list-mobile z-40">
      {photosToShow.map((p) => (
        <a
          href="https://www.instagram.com/lalcove/"
          rel="noopener noreferrer"
          target="_blank"
          className="post"
          key={p}
        >
          <figure className="post-image">
            <img src={p} alt={p} />
          </figure>
        </a>
      ))}
    </section>
  );
}
