import React from "react";
import FacebookBlue from "./../../assets/svg/logo/social/facebook-blue.svg";
import InstagramBlue from "./../../assets/svg/logo/social/instagram-blue.svg";
import GridPhoto from "../../components/grid-photos/gridPhotos";
import ArcheRetourne from "./../../assets/svg/arche/arche-retourne.svg";
import ArcheEndroit from "./../../assets/svg/arche/arche-endroit.svg";

export default function FollowUs() {
  return (
    <div id="follow-us" className="h-fit">
      <div className="flex justify-center items-center mt-20">
        <div className="flex mr-5">
          <a
            rel="noopener noreferrer"
            className="cursor-pointer"
            href="https://fr-fr.facebook.com/lalcove/"
            target="_blank"
          >
            <img
              className="mr-3"
              width={40}
              src={FacebookBlue}
              alt="facebook"
            />
          </a>
          <a
            rel="noopener noreferrer"
            className="cursor-pointer"
            href="https://www.instagram.com/lalcove/"
            target="_blank"
          >
            <img width={40} src={InstagramBlue} alt="instagram" />
          </a>
        </div>
        <div className="">
          <span className="font-helvetica tracking-[.1em] text-3xl font-semibold text-center">
            SUIVEZ
          </span>
          <span className="font-parisienne text-6xl">-nous</span>
        </div>
      </div>
      <div className="my-16 relative mb-32">
        <GridPhoto />
        <img
          className="absolute right-28 -z-40"
          style={{ top: -204 }}
          src={ArcheRetourne}
          width={400}
          height={500}
          alt="archeRetourne"
        />
        <img
          className="absolute left-28 -z-40"
          style={{ bottom: -457 }}
          src={ArcheEndroit}
          width={400}
          height={500}
          alt="archeEndroit"
        />
      </div>
    </div>
  );
}
