import React, { useState } from "react";
import MinusSvg from "../assets/svg/logo/icons/minus.svg";
import PlusSvg from "../assets/svg/logo/icons/plus.svg";
import { AccordionPricesDataType } from "./AccordionPrices";

const AccordionPricesMobile = (props: AccordionPricesDataType) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item flex flex-col bg-nude w-11/12 items-center m-auto my-6 rounded-xl">
      <div
        className="accordion-title cursor-pointer"
        style={{ width: 280 }}
        onClick={() => setIsActive(!isActive)}
      >
        {/* title */}
        <div className="flex text-md color-blue items-center my-3">
          <div className="rounded-full color-nude mt-1 mr-4 w-8 text-center">
            {isActive ? (
              <img src={MinusSvg} alt="minus" />
            ) : (
              <img src={PlusSvg} alt="plus" />
            )}
          </div>
          <span className="font-helvetica tracking-[.1em] font-bold text-lg mr-2 mt-2">
            {props.titlePart1}
          </span>
          <span className="font-parisienne text-4xl mt-1">
            {props.titlePart2}
          </span>
        </div>
      </div>
      {isActive && (
        <div key={props.id} className="mb-8">
          <div className="flex flex-col">
            <div className="ml-4 helvetica-nueu-thin tracking-[.1em] font-semibold text-xl">
              {props.titlePrices1}
            </div>
            {props.prices1.map((items, index) => (
              <div
                key={index}
                className="flex bg-poudre rounded-full color-nude helvetica-nueu-thin tracking-[.1em] text-sm/[11px] font-bold my-2 h-8 items-center"
                style={{ margin: "5px", lineHeight: "1" }}
              >
                <div className="items-center ml-3 w-full">{items.title}</div>
                <div className="text-end mr-3">{items.price}</div>
              </div>
            ))}
          </div>
          <div className="flex flex-col">
            <div className="ml-4 helvetica-nueu-thin tracking-[.1em] font-semibold text-xl mt-6">
              {props.titlePrices2}
            </div>
            {props.prices2.map((items, index) => (
              <div
                key={index}
                className="flex bg-poudre rounded-full color-nude helvetica-nueu-thin tracking-[.1em] text-sm/[11px] font-bold my-2 h-8 items-center"
                style={{ margin: "5px", lineHeight: "1" }}
              >
                <div className="items-center ml-3 w-full">{items.title}</div>
                <div className="text-end mr-3">{items.price}</div>
              </div>
            ))}

            {props.quote.map((item, index) => (
              <div
                key={index}
                className="helvetica-nueu-thin tracking-[.1em] text-sm/[11px] font-bold text-right mr-7"
                style={{ margin: "5px", lineHeight: "1" }}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AccordionPricesMobile;
