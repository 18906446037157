import React from "react";
import Carousel from "./../../components/carousel/carousel";

export default function WhatTheySay() {
  return (
    <div id="what-they-say" className="mud-img">
      <Carousel />
    </div>
  );
}
