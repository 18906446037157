import React from "react";
import ArcheEndroit from "../assets/svg/arche/arche-endroit.svg";
import ArcheRetourne from "../assets/svg/arche/arche-retourne.svg";

export default function LegalNotice() {
  return (
    <article id="legal-notice">
      <section className="helvetiva text-4xl font-semibold color-blue text-center mt-52 mb-24">
        MENTIONS LÉGALES
      </section>
      <section className="helvetica flex-col bg-poudre text-center py-14 relative mb-16">
        <p>Le présent site est la propriété de la SARL Lookencil</p>
        <p>Siège social : 229 avenue Jean Jaurès 92140 Clamart</p>
        <p>Téléphone : 06 87 68 70 66</p>
        <p>SARL au capital de 5000 euros</p>
        <p>Siret : 789 796 182 00028 </p>
        <p>RCS : 789 796 182 - Nanterre</p>
        <p>Directeur de la publication : Caroline Delanoue</p>
        <div className="absolute top-0 right-20">
          <img src={ArcheRetourne} width={350} alt="ArcheRetourne" />
        </div>
      </section>

      {/* 1 */}
      <section className="helvetiva mx-72 my-8">
        <h2 className="text-4xl font-semibold color-blue">
          1.PROPRIÉTÉ INTELECTUELLE
        </h2>
        <br />
        <p className="mb-8 helvetica">
          Les photographies ont été réalisée par Giles Vidal et demeurent sa
          propriété intelectuelle. Des droits de diffusion et d’exploitation ont
          été cédés.
          <br />
          Le site utilise également certaines photographies libres de droit des
          sites Unsplash.com et Pexels.com <br /> Crédits illustrations : Page
          Error 404 : Image de rawpixel.com sur Freepik
        </p>

        {/* 2 */}
        <h2 className="text-4xl font-semibold color-blue">2. HÉBERGEMENT</h2>
        <br />
        <p>
          Le présent site est hébergé par la société one.com <br />
          dont le siège social est localisé à l'adresse suivante : 2 IMP MAXIME
          LISBONNE 91000 EVRY-COURCOURONNES <br /> Le site a été designé par
          Joanna Villemain, designer graphique indépendante (
          <a
            href="https://www.joannavillemain.com/"
            target="_blank"
            rel="noreferrer"
            style={{
              margin: "0 3px",
              textDecoration: "underline",
            }}
          >
            joannavillemain.com
          </a>
          ) et développé par Tom Paya développeur fullstack.
        </p>
      </section>

      {/* 3 */}
      <section className="helvetiva mx-72 my-8 relative">
        <h2 className="text-4xl font-semibold color-blue">
          3. POLITIQUE DE COLLECTE DES DONNÉES
        </h2>
        <br />
        <p className="mb-80">
          Le site internet de l’Alcôve n’utilise aucun cookies et ne collecte
          aucune données pour son compte. <br />
          Nous ne sommes pas responsable des données colllectées par notre
          hébergeur (one.com) ni notre partenaire Planity. Pour connaître, leur
          politique de collecte des données rendez-vous sur leur site internet.
        </p>
        <div className="absolute -bottom-80 -left-20 -z-10">
          <img src={ArcheEndroit} width={350} alt="archeEndroit" />
        </div>
      </section>
    </article>
  );
}
