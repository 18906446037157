import React from "react";
import LalcoveSoloNameNude from "./../../assets/svg/logo/logo_nude/logo_nom_solo_nude.svg";
import PhonePoudre from "./../../assets/svg/logo/social/phone.svg";
import MenuPoudre from "./../../assets/svg/logo/social/menu.svg";
import CrossPoudre from "./../../assets/svg/logo/social/cross.svg";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import Home from "../../pages/home";
import AboutMobile from "../../pages/about/PresentationMobile";
import BenefitsMobile from "../../pages/benefitsMobile";
import PricesMobile from "../../pages/pricesMobile";
import LegalNoticeMobile from "../../pages/legalNoticeMobile";
import { useSpring, animated } from "@react-spring/web";
import MenuMobile from "../../pages/menuMobile";
import RendezVousMobile from "../rendez-vous/rendezVousMobile";

export default function NavbarMobile() {
  // gestion de l'animation
  const anim = useSpring({
    opacity: 0.65,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(0px, -47rem)" },
    config: { duration: 400, opacity: 0 },
  });

  const location = useLocation();

  return (
    <>
      <header>
        <animated.nav
          className="fixed flex w-full bg-poudre py-5 z-50"
          style={anim}
        >
          <div className="grid grid-cols-6 items-center">
            <Link className="col-span-3 ml-8" to="/">
              <img src={LalcoveSoloNameNude} width={132} alt="lalcoveSolo" />
            </Link>
            <Link className="col-start-5 mt-2 ml-9" to="/rendez-vous-mobile">
              <img src={PhonePoudre} width={25} alt="PhonePoudre" />
            </Link>
            <Link
              to={location.pathname !== "/menu-mobile" ? "/menu-mobile" : "/"}
            >
              <img
                src={
                  location.pathname === "/menu-mobile"
                    ? CrossPoudre
                    : MenuPoudre
                }
                className="mt-2 ml-4"
                width={30}
                alt="MenuPoudre"
              />
            </Link>
          </div>
        </animated.nav>
      </header>

      {/* Routes elements */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/menu-mobile" element={<MenuMobile />} />
        <Route path="/rendez-vous-mobile" element={<RendezVousMobile />} />
        <Route path="/a-propos-mobile" element={<AboutMobile />} />
        <Route path="/prestations-mobile" element={<BenefitsMobile />} />
        <Route path="/nos-tarifs-mobile" element={<PricesMobile />} />
        <Route path="/mentions-legal-mobile" element={<LegalNoticeMobile />} />
      </Routes>
    </>
  );
}
