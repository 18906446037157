import React from "react";
import Interior from "./../../assets/img/interior.png";
import { Link } from "react-router-dom";

export default function PresentationMobile() {
  return (
    <div id="presentation">
      <img
        alt="Wall"
        className="rounded-tl-full rounded-tr-full absolute"
        width={170}
        style={{ top: 1100, right: 30 }}
        src={Interior}
      />
      <div className="bg-light">
        <div className="flex-col text-xl color-nude p-10 pt-52">
          <div>
            <div className="helvetica-nueu-thin tracking-[.1em] text-xl">
              <span className="font-semibold">
                AU CŒUR DE CLAMART, UN MOMENT{" "}
              </span>
              <span className="font-parisienne text-3xl"> rien qu’à vous.</span>
            </div>
          </div>
          <br />
          <p className="helvetica-nueu-thin tracking-[.05em]">
            L’Alcôve est un institut de beauté contemporain dédié au soin de vos
            cils, de vos sourcils et de vos ongles. Caroline et Alicia vous
            accueillent à Clamart du lundi au samedi pour vous proposer : pose
            de vernis semi- permanent ou gel, extensions de cils, volume russe,
            un rehaussement ou une teinture. Nous aimons particulièrement vous
            conseiller et nous nous adaptons toujours à vos envies du moment.
            Nous sommes passionnées par les métiers de la beauté et du soin et
            nous suivons les dernières tendances avec attention. Appelez-nous
            pour en savoir plus ou passez nous voir, c’est toujours un plaisir
            d’échanger avec vous.
          </p>
        </div>
      </div>
      <div className="flex flex-1 justify-center -mt-5">
        <button
          className="flex justify-center tracking-[.1em] text-sm align-center font-helvetica bg-poudre color-nude rounded-full"
          style={{
            width: 270,
            height: 45,
            marginBottom: -20,
          }}
        >
          <Link
            to="/a-propos-mobile"
            className="text-xl"
            style={{
              lineHeight: 2,
            }}
          >
            NOTRE HISTOIRE
          </Link>
        </button>
      </div>
    </div>
  );
}
