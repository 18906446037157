import React from "react";
import Presentation from "./about/Presentation";

export default function About() {
  return (
    <article>
      <Presentation />
    </article>
  );
}
