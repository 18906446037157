import AccordionPrices, {
  AccordionPricesDataType,
} from "../components/AccordionPrices";

export default function Prices() {
  // gestion accordéon
  const accordionPricesData: AccordionPricesDataType[] = [
    {
      id: 1,
      titlePart1: "BEAUTÉ DES",
      titlePart2: "cils",
      titleContent1: "LES EXTENSIONS",
      titlePrices1: "LES EXTENSIONS",
      prices1: [
        { title: "Pose complète cil à cil", price: "150€" },
        { title: "Retouche (à 3 semaines maximum)", price: "55€" },
        { title: "Pose mixte", price: "175€" },
        { title: "Retouche (à 3 semaines maximum)", price: "65€" },
        { title: "Pose complète volume russe", price: "200€" },
        { title: "Retouche (à 3 semaines maximum)", price: "75€" },
        { title: "Dépose", price: "30€" },
      ],
      titlePrices2: "EMBELLISSEMENT NATUREL",
      prices2: [
        { title: "Réhaussement de cils", price: "70€" },
        { title: "Teinture de cils", price: "25€" },
        { title: "Réhaussement de cils + Teinture", price: "90€*" },
      ],
      quote: ["*avec soin «3D filler» + 10€"],
    },
    {
      id: 2,
      titlePart1: "BEAUTÉ DES",
      titlePart2: "sourcils",
      titleContent1: "",
      titlePrices1: "",
      prices1: [
        { title: "Restructuration", price: "20€*" },
        { title: "Entretien", price: "15€*" },
        { title: "Teinture", price: "15€" },
        { title: "Browlift", price: "50€" },
        { title: "Henné sourcils avec épilation", price: "40€" },
      ],
      titlePrices2: "",
      prices2: [],
      quote: ["*avec teinture + 10€"],
    },
    {
      id: 3,
      titlePart1: "BEAUTÉ DES",
      titlePart2: "ongles",
      titleContent1: "",
      titlePrices1: "MAINS & PIEDS",
      prices1: [
        { title: "Rallongement au gel (chablons) avec couleur", price: "65€" },
        { title: "Gainage sur ongle naturel", price: "50€" },
        { title: "Remplissage", price: "55€" },
        { title: "Vernis semi-permanent", price: "45€" },
        { title: "Vernis classique", price: "20€" },
        { title: "Manucure russe", price: "15€" },
        { title: "Nail art, dégradé, strass", price: "à partir de 1€ /ongle" },
        { title: "Dépose vernis semi-permanent ou gel", price: "20€" },
      ],
      titlePrices2: "",
      prices2: [],
      quote: [
        "*la dépose est offerte pour une nouvelle pose de semi-permanent",
      ],
    },
    {
      id: 4,
      titlePart1: "Les",
      titlePart2: "soins",
      titleContent1: "LES SOINS",
      titlePrices1: "LES SOINS",
      prices1: [
        { title: "Beauté des mains avec gommage et modelage", price: "30€*" },
        { title: "Beauté des pieds avec modelage relaxant", price: "35€*" },
        { title: "Beauté des pieds hydratation intense 1 heure", price: "60€" },
        { title: "Soin anti-callosité", price: "45€" },
      ],
      titlePrices2: "",
      prices2: [],
      quote: ["*avec semi-permanent + 40 €", "*avec vernis classique + 10 €"],
    },
  ];
  return (
    <>
      {/* top */}
      <article className="color-blue mb-10">
        <div className="justify-center flex text-md mt-56 color-blue items-center w-full">
          <span className="font-helvetica tracking-[.1em] text-md font-bold text-2xl mr-2 mt-2">
            Nos
          </span>
          <span className="font-parisienne text-6xl">tarifs</span>
        </div>
      </article>

      {/* accordeon */}
      <article className="bg-light p-1">
        {accordionPricesData.map((item: AccordionPricesDataType) => (
          <AccordionPrices
            id={item.id}
            titlePart1={item.titlePart1}
            titlePart2={item.titlePart2}
            titleContent1={item.titleContent1}
            titlePrices1={item.titlePrices1}
            prices1={item.prices1}
            titlePrices2={item.titlePrices2}
            prices2={item.prices2}
            quote={item.quote}
          />
        ))}
      </article>
    </>
  );
}
