import React from "react";
import { useSpring, animated } from "@react-spring/web";
import ArcheEndroit from "./../../assets/svg/arche/arche-endroit-mobile.svg";
import ArcheRetourne from "./../../assets/svg/arche/arche-retourne.svg";
import Cils from "./../../assets/img/beaute_des_cils.png";
import Sourcils from "./../../assets/img/beaute_des_sourcils.png";
import Ongles from "./../../assets/img/beaute_des_ongles.png";
import Soins from "./../../assets/img/nos_soins.png";

export default function DiscoverMobile() {
  const arch = useSpring({
    width: 200,
    height: 260,
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(0px, 10rem)" },
    config: { duration: 400, opacity: 0 },
  });

  return (
    <div id="discover" className="relative mb-20">
      <div className="absolute -z-50" style={{ top: 400, left: 10 }}>
        <img src={ArcheEndroit} width={275} alt="archeEndroit" />
      </div>
      <div className="absolute -z-50" style={{ top: -150, left: 10 }}>
        <img src={ArcheRetourne} width={275} alt="archeRetourne" />
      </div>
      <div className="flex mt-40">
        <animated.div style={arch} className="flex justify-center">
          <img
            className="absolute rounded-tl-full rounded-tr-full shadow-md"
            width={150}
            src={Cils}
            alt="Cils"
            style={{ marginTop: 35 }}
          />
          <div
            style={{ width: 150 }}
            className="flex justify-center items-center text-center font-helvetica text-md bg-poudre color-nude tracking-[.1em] py-1 h-20 bottom-0 absolute"
          >
            BEAUTÉ <br />
            DES CILS
          </div>
        </animated.div>
        <animated.div style={arch} className="flex justify-center">
          <img
            className="absolute rounded-tl-full rounded-tr-full shadow-md"
            width={150}
            src={Sourcils}
            alt="Sourcils"
            style={{ marginTop: 35 }}
          />
          <div
            style={{ width: 150 }}
            className="flex justify-center items-center text-center font-helvetica text-md bg-poudre color-nude tracking-[.1em] py-1 h-20 bottom-0 absolute w-full"
          >
            BEAUTÉ <br />
            DES SOURCILS
          </div>
        </animated.div>
      </div>
      <div className="flex">
        <animated.div style={arch} className="flex justify-center">
          <img
            className="absolute rounded-tl-full rounded-tr-full shadow-md"
            width={150}
            src={Ongles}
            alt="Ongles"
            style={{ marginTop: 35 }}
          />
          <div
            style={{ width: 150 }}
            className="flex justify-center items-center text-center font-helvetica text-lg bg-poudre color-nude tracking-[.1em] py-1 h-20 bottom-0 absolute w-full"
          >
            BEAUTÉ <br />
            DES ONGLES
          </div>
        </animated.div>
        <animated.div style={arch} className="flex justify-center">
          <img
            className="absolute rounded-tl-full rounded-tr-full shadow-md"
            width={150}
            src={Soins}
            alt="Soins"
            style={{ marginTop: 35 }}
          />
          <div
            style={{ width: 150 }}
            className="flex justify-center items-center text-center font-helvetica text-lg bg-poudre color-nude tracking-[.1em] py-1 h-20 bottom-0 absolute w-full"
          >
            NOS SOINS
          </div>
        </animated.div>
      </div>
    </div>
  );
}
